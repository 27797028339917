import styled from "@emotion/styled"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import { ButtonWithArrow } from "../components/button"
import Card, { CardWrapper } from "../components/card"
import Layout from "../components/layout"
import Typography from "../components/typography"
import colors from "../styles/colors"
import {
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_HORIZONTAL,
} from "../styles/constants"
import SEO from "../components/seo"
import { SuccessStory } from "../types/widget"

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
`

const CustomerStoriesWrapper = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0 ${PADDING_HORIZONTAL}px;
  margin-bottom: 5rem;

  ${MobileMediaQuery} {
    padding: 0 ${MOBILE_PADDING_HORIZONTAL}px;
    margin-bottom: 5rem;
  }
`

const Title = styled(Typography)`
  color: ${colors.primary};
  margin-bottom: 1rem;
`
const CompanyName = styled(Typography)`
  color: ${colors.primary};
  margin-bottom: 2rem;
`

const ImageWrapper = styled.div`
  max-width: 680px;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
`

const ExploreButton = styled(ButtonWithArrow)`
  margin-top: 2rem;

  &:hover {
    background-color: #666;
    border-color: #666;
    color: ${colors.white};
  }
`
export type SuccessStories = {
  contentfulSuccessStories: {
    title: string
    intro: { intro: string }
    stories: SuccessStory[]
  }
}

interface CustomersProps {
  data: SuccessStories
}

const Customers = ({ data }: CustomersProps) => {
  const {
    title,
    intro: { intro },
    stories,
  } = data.contentfulSuccessStories

  return (
    <Layout bg="white" newsletter={null} activeTab="learn">
      <SEO title="Success stories" />
      <Card variant="white" noMinHeight>
        <TopWrapper>
          <Title variant="h1">{title}</Title>
          <Typography variant="bodyLarge">{intro}</Typography>
        </TopWrapper>
      </Card>

      {stories.map(customer => (
        <CustomerStoriesWrapper variant="white" key={customer.companyName}>
          <ImageWrapper>
            <GatsbyImage
              image={customer.productExcerptPicture.gatsbyImageData}
              alt={customer.productExcerptPicture.title ?? "image"}
            />
          </ImageWrapper>
          <CompanyName variant="h6">{customer.companyName}</CompanyName>
          <Typography variant="h3" style={{ maxWidth: "600px" }}>
            {customer.excerpt.excerpt}
          </Typography>
          <ExploreButton
            variant="white"
            arrowWidth="16px"
            to={`/customers/${customer.slug}`}
          >
            Explore the customer story
          </ExploreButton>
        </CustomerStoriesWrapper>
      ))}
    </Layout>
  )
}

export default Customers

export const query = graphql`
  query SuccessStoriesMain {
    contentfulSuccessStories {
      title
      intro {
        intro
      }
      stories {
        companyName
        productExcerptPicture {
          gatsbyImageData
          title
        }
        excerpt {
          excerpt
        }
        slug
      }
    }
  }
`
